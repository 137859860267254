<template>
	<div class="page">
		<div><h2 id="gtdlt">推品小助手使用指南 V3.0</h2></div>
		<div>小助手共可有4个功能：依次是“群管理、朋友圈、入群欢迎语、自动回复”等，下面我们就来具体看看每个功能有什么作用和如何设置吧！👇👇</div>
		<div style="font-weight: normal;"><router-link :to="{path: '/setTutorial', query: {pageSize: '0'}}">一、设置“群管理”</router-link></div>
		<div style="font-weight: normal;"><router-link :to="{path: '/setTutorial', query: {pageSize: '1'}}">二、设置“朋友圈”</router-link></div>
		<div style="font-weight: normal;"><router-link :to="{path: '/setTutorial', query: {pageSize: '2'}}">三、设置“入群欢迎语”</router-link></div>
		<div style="font-weight: normal;"><router-link :to="{path: '/setTutorial', query: {pageSize: '3'}}">四、设置“自动回复”</router-link></div>
		<div>小助手虽好，但我们也不能完全当“甩手掌柜”哟！一个有温度的卖货群，才会更持久，氛围营造、晒单、买家秀、发福利，还是要大大们每天花时间自己来哟！😀<br/><br/></div>
	</div>

</template>

<script>
export default {
	name: 'index'
}
</script>

<style scoped lang="scss">

@import './index';
.page{
	width: 345px;
	box-sizing: border-box;
	background: white;
	padding-bottom: px(60);

}
</style>
